import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_THIRD } from '@theme_color';
import {
    FlexColumn, FlexRow, CreateMargin, CreatePadding,
} from '@theme_mixins';

export const useStyles = makeStyles(() => ({
    container: {
        ...FlexColumn,
        ...CreatePadding(0, 0, 0, 0),
    },
    spanLabelPrice: {
        ...FlexRow,
        justifyContent: 'space-between',
        ...CreateMargin(0, 0, 0, 0),
        '& span': {
            fontSize: '12px',
        },
    },
}));

export const sliderStyle = {
    root: {
        color: GRAY_THIRD,
        height: 4,
        width: '100%',
    },
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#185A58',
        marginTop: -5,
        marginLeft: -6,
        '&:focus, &:hover, &$active': {
            boxShadow: 'primary',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 4,
        borderRadius: 0,
        color: '#6BC498',
    },
    rail: {
        height: 4,
        borderRadius: 0,
        color: '#F1F1F1',
    },
};
