import makeStyles from '@material-ui/core/styles/makeStyles';
import { FlexColumn } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        padding: '20px 41px',
        [theme.breakpoints.down('sm')]: {
            padding: '16px 17px',
        },
        ...FlexColumn,
    },
    headContainer: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        display: 'none',
    },
    headContainerNoBanner: {
        backgroundColor: '#fff',
        height: '40vh',
    },
    header: {
        left: '50%',
        right: '50%',
        top: '11px',
        position: 'absolute',
        borderBottom: 'none',
        fontWeight: 'bold',
    },
    breadcrumbs: {
        // padding: '16px 16px 0',
        padding: '0px',
    },
    categoryName: {
        margin: 0,
        marginBottom: '20px',
        padding: 0,
        fontSize: '30px',
        fontWeight: 'bold',
    },
}));

export default useStyles;
