/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
import Typography from '@common_typography';
import IconButton from '@material-ui/core/IconButton';
import Favorite from '@material-ui/icons/Favorite';
import classNames from 'classnames';
import React from 'react';
import { getHost } from '@helper_config';
import Breadcrumb from '@common_breadcrumb';
import { breakPointsUp } from '@helper_theme';
import dynamic from 'next/dynamic';
import useStyles from '@core_modules/product/pages/default/components/style';
import OptionItem from '@core_modules/product/pages/default/components/OptionItem';
import SharePopup from '@core_modules/product/pages/default/components/SharePopup';
import ModalPopupImage from '@core_modules/product/pages/default/components/ModalPopupImage';
import { modules } from '@config';
import { getProductBannerLite } from '@core_modules/product/services/graphql';
import { formatPrice } from '@helper_currency';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import Link from 'next/link';
import { useSubscribeButton } from '@core_modules/product/hooks/useSubscribeProduct';

const Button = dynamic(() => import('@common_button'), { ssr: true });
const Banner = dynamic(() => import('@common_slick/BannerThumbnail'), { ssr: true });
const DesktopOptions = dynamic(() => import('@core_modules/product/pages/default/components/OptionItem/DesktopOptions'), { ssr: true });
const ExpandDetail = dynamic(() => import('@core_modules/product/pages/default/components/ExpandDetail'), { ssr: false });
const TabsView = dynamic(() => import('@core_modules/product/pages/default/components/DesktopTabs'), { ssr: false });
const PriceFormat = dynamic(() => import('@common_priceformat'), { ssr: true });
const RatingStar = dynamic(() => import('@common_ratingstar'), { ssr: true });
const ItemShare = dynamic(() => import('@core_modules/product/pages/default/components/SharePopup/item'), { ssr: false });
const WeltpixelLabel = dynamic(() => import('@plugin_productitem/components/WeltpixelLabel'), { ssr: false });
const UpsellDrawer = dynamic(() => import('@core_modules/product/pages/default/components/RightDrawer'), { ssr: false });
const RelatedProductCaraousel = dynamic(() => import('@core_modules/product/pages/default/components/RelatedProductCaraousel'), { ssr: false });
const PromoBannersLite = dynamic(() => import('@core_modules/product/pages/default/components/PromoBannersLite'), { ssr: false });

const ProductPage = (props) => {
    const styles = useStyles();
    const {
        t,
        data,
        openOption,
        handleOption,
        setOpenOption,
        setBanner,
        setPrice,
        openShare,
        setOpenShare,
        route,
        banner,
        openDrawer,
        setOpenDrawer,
        breadcrumbsData,
        price,
        handleWishlist,
        reviewValue,
        wishlist,
        expandData,
        openImageDetail,
        handleOpenImageDetail,
        // stockStatus,
        additionalPrice,
        smartProductTabs,
        isLogin,
        handleSetCompareList,
        enablePopupImage,
        storeConfig,
        companyStatus,
        customerCode,
        productId,
        subscribed,
    } = props;
    const desktop = breakPointsUp('sm');
    const verdorProductData = data?.vendor_product_data?.[0];

    const context = (isLogin && isLogin === 1) ? { request: 'internal' } : {};
    const [getBannerLite, bannerLiteResult] = getProductBannerLite(route.asPath.slice(1), { context });

    React.useEffect(() => {
        getBannerLite();
    }, [bannerLiteResult.called]);

    let bannerLiteData = [];
    if (bannerLiteResult && bannerLiteResult.data && bannerLiteResult.data.products.items
        && bannerLiteResult.data.products.items.length > 0 && bannerLiteResult.data.products.items[0].banners_data) {
        bannerLiteData = bannerLiteResult.data.products.items[0].banners_data;
    }
    const bannerLiteObj = {
        top: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '0') || [],
        after: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '1') || [],
        label: bannerLiteData.filter((bannerLite) => bannerLite.banner_type === '2') || [],
    };

    const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <img className={styles.iconFeed} src="/assets/img/whislistIcon.svg" alt="whislist" />;

    const [handleSubscribeClick, subscribeBtnText, subscribeBtnLoading] = useSubscribeButton(productId, subscribed);

    return (
        <>
            <div className="hidden-mobile">
                <UpsellDrawer
                    open={openDrawer}
                    setOpen={() => setOpenDrawer(!openDrawer)}
                    t={t}
                    dataProduct={data}
                    isLogin={isLogin}
                    storeConfig={storeConfig}
                />
                {
                    enablePopupImage && (
                        <ModalPopupImage open={openImageDetail} setOpen={handleOpenImageDetail} banner={banner} storeConfig={storeConfig} />
                    )
                }
            </div>
            <OptionItem {...props} open={openOption} setOpen={() => setOpenOption(!openOption)} setBanner={setBanner} setPrice={setPrice} />
            <SharePopup open={openShare} setOpen={() => setOpenShare(!openShare)} link={getHost() + route.asPath} {...props} />
            <div className={classNames(styles.container, 'row')}>
                <div className={classNames(styles.desktopBreadcrumb, 'col-lg-12 hidden-mobile')}>
                    <Breadcrumb data={breadcrumbsData} variant="text" />
                </div>

                {(bannerLiteObj.top && bannerLiteObj.top.length > 0) && (
                    bannerLiteObj.top.map((topBanner) => (
                        <PromoBannersLite
                            type="top"
                            key={topBanner.entity_id}
                            classes={classNames(styles.bannerLiteTop, 'col-xs-12')}
                            src={topBanner.banner_link}
                            imgSrc={topBanner.banner_image}
                            alt={topBanner.banner_alt}
                            storeConfig={storeConfig}
                        />
                    ))
                )}

                <div className={classNames(styles.headContainer, 'col-xs-12 col-lg-6')}>
                    {(bannerLiteObj.top && bannerLiteObj.top.length > 0) && (
                        bannerLiteObj.top.map((topBanner) => (
                            <PromoBannersLite
                                type="top"
                                key={topBanner.entity_id}
                                classes={classNames(styles.bannerLiteTopMobile, 'col-xs-12')}
                                src={topBanner.banner_link}
                                imgSrc={topBanner.banner_image}
                                alt={topBanner.banner_alt}
                                storeConfig={storeConfig}
                            />
                        ))
                    )}
                    <div className="row">
                        {(bannerLiteObj.label && bannerLiteObj.label.length > 0) && (
                            bannerLiteObj.label.map((labelBanner) => (
                                <PromoBannersLite
                                    type="label"
                                    key={labelBanner.entity_id}
                                    classes={classNames(styles.bannerLiteLabel, 'col-xs-6')}
                                    imgSrc={labelBanner.banner_image}
                                    alt={labelBanner.banner_alt}
                                    storeConfig={storeConfig}
                                />
                            ))
                        )}
                    </div>
                    <Banner
                        data={banner}
                        noLink
                        showDots={false}
                        thumbnail
                        showArrow
                        contentWidth="auto"
                        autoPlay={false}
                        width={416}
                        height={416}
                        actionImage={(desktop && enablePopupImage) ? handleOpenImageDetail : () => { }}
                        customProduct={styles.bannerProduct}
                        storeConfig={storeConfig}
                    >
                        {
                            storeConfig?.pwa?.label_enable
                            && storeConfig?.pwa?.label_weltpixel_enable && (
                                <WeltpixelLabel
                                    t={t}
                                    weltpixel_labels={data.weltpixel_labels || []}
                                    categoryLabel={false}
                                    withThumbnailProduct
                                />
                            )
                        }
                    </Banner>
                    <div className="hidden-desktop">
                        <UpsellDrawer
                            open={openDrawer}
                            setOpen={() => setOpenDrawer(!openDrawer)}
                            t={t}
                            dataProduct={data}
                            isLogin={isLogin}
                        />
                    </div>
                </div>
                <div className={classNames(styles.body, 'col-xs-12 col-lg-6')}>
                    <div className={classNames(styles.titleContainer, 'hidden-desktop')}>
                        <Breadcrumb data={breadcrumbsData} variant="text" />
                    </div>

                    <div className={styles.titleContainer}>
                        <div className={styles.titlePriceContainer}>
                            <Typography variant="title" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                {data.name}
                            </Typography>
                        </div>
                    </div>
                    <div className={styles.ratingWrapper}>
                        <div className={classNames('row', styles.sku)}>
                            <Typography className="clear-margin-padding" variant="p" type="regular" letter="capitalize">
                                SKU#:
                                {' '}
                            </Typography>
                            <Typography variant="p" type="bold" letter="none">
                                {data.sku || ''}
                            </Typography>
                        </div>
                        <div className={styles.ratingContainer}>
                            <RatingStar value={reviewValue || 0} />
                            <Typography variant="p" type="regular" letter="capitalize">
                                (
                                {data.review.reviews_count || 0}
                                )
                            </Typography>
                        </div>
                    </div>

                    {
                        (isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                            <>
                                {
                                    (verdorProductData.vendor_id && verdorProductData.final_price) && (
                                        <>
                                            <div className={styles.priceWrapper}>
                                                <PriceFormat
                                                    {...price}
                                                    additionalPrice={additionalPrice}
                                                    vendorProductData={
                                                        data && data.vendor_product_data && data.vendor_product_data.length > 0
                                                            ? data.vendor_product_data[0]
                                                            : null
                                                    }
                                                />
                                            </div>
                                            <div className={styles.titleContainer}>
                                                <div className={styles.priceTiersContainer}>
                                                    {
                                                        verdorProductData.final_pareto_price ? (
                                                            <>
                                                                <Typography variant="p" type="bold" size="14">
                                                                    Harga Grosir
                                                                </Typography>
                                                                <Typography variant="p" type="regular">
                                                                    {t('product:priceTiers', {
                                                                        priceTiers: {
                                                                            quantity: verdorProductData.pareto_price_min_qty || 0,
                                                                            price: formatPrice(verdorProductData.final_pareto_price, verdorProductData.currency_code),
                                                                        },
                                                                    })}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    verdorProductData.tier_price
                                                                    && verdorProductData.tier_price.length > 0 && (
                                                                        <>
                                                                            <Typography variant="p" type="bold" size="14">
                                                                                Harga Grosir
                                                                            </Typography>
                                                                            {
                                                                                verdorProductData.tier_price
                                                                                    .map((tiers, index) => {
                                                                                        const priceTiers = {
                                                                                            quantity: tiers.quantity,
                                                                                            price: formatPrice(tiers.final_price, verdorProductData.currency_code),
                                                                                        };
                                                                                        return (
                                                                                            <Typography variant="p" type="regular" key={index}>
                                                                                                {t('product:priceTiers', { priceTiers })}
                                                                                            </Typography>
                                                                                        );
                                                                                    })
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>

                                            <div className="row">
                                                {
                                                    storeConfig?.pwa?.label_enable
                                                    && storeConfig?.pwa?.label_weltpixel_enable && (
                                                        <WeltpixelLabel
                                                            t={t}
                                                            weltpixel_labels={data.weltpixel_labels || []}
                                                            categoryLabel={false}
                                                            onDetailProduct
                                                        />
                                                    )
                                                }
                                            </div>
                                        </>
                                    )
                                }
                                <div className={classNames(styles.desktopShare, 'hidden-desktop')}>
                                    <div className={styles.leftShare}>
                                        <IconButton className={styles.btnCompare} onClick={handleWishlist}>
                                            {favoritIcon}

                                            <Typography variant="p" letter="capitalize">
                                                {t('product:wishlishProduct')}
                                            </Typography>
                                        </IconButton>
                                        {modules.productcompare.enabled && (
                                            <IconButton className={styles.btnCompare} color="primary" onClick={() => handleSetCompareList(data.id)}>
                                                <img src="/assets/img/plpcompareIcon.svg" alt="compare" />
                                                <Typography variant="p" letter="capitalize">
                                                    {t('product:compareProduct')}
                                                </Typography>
                                            </IconButton>
                                        )}
                                    </div>
                                    <div className={styles.rightShare}>
                                        <Typography className={styles.shareTitle} variant="p">
                                            {t('product:shareTitle')}
                                        </Typography>
                                        <div>
                                            <ItemShare link={getHost() + route.asPath} />
                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    }

                    <div className="hidden-desktop">
                        {
                            (isLogin === 1 && companyStatus === 'approved' && customerCode) && (
                                <div className={styles.desc}>
                                    <Typography variant="span" type="regular" size="10">
                                        {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                                    </Typography>
                                </div>
                            )
                        }
                        <div>
                            <ExpandDetail propsData={props} data={expandData} smartProductTabs={smartProductTabs} />
                        </div>
                        <div className="row">
                            {(bannerLiteObj.after && bannerLiteObj.after.length > 0) && (
                                bannerLiteObj.after.map((afterBanner) => (
                                    <PromoBannersLite
                                        type="after"
                                        key={afterBanner.entity_id}
                                        classes={classNames(styles.bannerLiteAfter, 'col-xs-6')}
                                        src={bannerLiteObj.after.banner_link}
                                        imgSrc={afterBanner.banner_image}
                                        alt={afterBanner.banner_alt}
                                        storeConfig={storeConfig}
                                    />
                                ))
                            )}
                        </div>
                    </div>

                    <div className="hidden-mobile">
                        {
                            (isLogin === 1 && customerCode) ? (
                                <>
                                    {
                                        (isLogin === 1 && companyStatus === 'approved') ? (
                                            <>
                                                <div>
                                                    {
                                                        (verdorProductData.vendor_id && verdorProductData.final_price) ? (
                                                            <DesktopOptions {...props} setOpen={setOpenOption} setBanner={setBanner} setPrice={setPrice} />
                                                        ) : (
                                                            <div>
                                                                <Typography className={styles.outStockText} variant="p" type="regular">
                                                                    Out of Stock
                                                                </Typography>
                                                                <div className={styles.notifyWrapper}>
                                                                    <Button
                                                                        className={styles.btnNotify}
                                                                        customRootStyle={{
                                                                            width: 'auto',
                                                                        }}
                                                                        color="primary"
                                                                        align="left"
                                                                        onClick={handleSubscribeClick}
                                                                        loading={subscribeBtnLoading}
                                                                    >
                                                                        <NotificationsNoneOutlinedIcon />
                                                                        <span className="notify-text">
                                                                            {subscribeBtnText}
                                                                        </span>
                                                                    </Button>
                                                                    <Typography className={styles.stockInfo} variant="p" type="regular">
                                                                        Beri tahu saya ketika ready stok
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div style={{ marginTop: '36px' }} className="row">
                                                    {(bannerLiteObj.after && bannerLiteObj.after.length > 0) && (
                                                        bannerLiteObj.after.map((afterBanner) => (
                                                            <PromoBannersLite
                                                                type="after"
                                                                key={afterBanner.entity_id}
                                                                classes={classNames(styles.bannerLiteAfter, 'col-xs-6')}
                                                                src={bannerLiteObj.after.banner_link}
                                                                imgSrc={afterBanner.banner_image}
                                                                alt={afterBanner.banner_alt}
                                                                storeConfig={storeConfig}
                                                            />
                                                        ))
                                                    )}
                                                </div>

                                                <div className={styles.desktopShare}>
                                                    <div className={styles.leftShare}>
                                                        <IconButton className={styles.btnCompare} onClick={handleWishlist}>
                                                            {favoritIcon}

                                                            <Typography variant="p" letter="capitalize">
                                                                {t('product:wishlishProduct')}
                                                            </Typography>
                                                        </IconButton>
                                                        {modules.productcompare.enabled && (
                                                            <IconButton className={styles.btnCompare} color="primary" onClick={() => handleSetCompareList(data.id)}>
                                                                <img src="/assets/img/plpcompareIcon.svg" alt="compare" />
                                                                <Typography variant="p" letter="capitalize">
                                                                    {t('product:compareProduct')}
                                                                </Typography>
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                    <div className={styles.rightShare}>
                                                        <Typography className={styles.shareTitle} variant="p">
                                                            {t('product:shareTitle')}
                                                        </Typography>
                                                        <div>
                                                            <ItemShare link={getHost() + route.asPath} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.desc}>
                                                    <Typography variant="span" type="regular" size="10">
                                                        {data.short_description.html ? <span dangerouslySetInnerHTML={{ __html: data.short_description.html }} /> : null}
                                                    </Typography>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                {
                                                    isLogin !== 1 ? (
                                                        <div className={styles.loginWrapper}>
                                                            <Typography className={styles.loginInfoText} variant="p" type="regular">
                                                                {t('product:unregisteredMessage')}
                                                            </Typography>
                                                            <Link href="/customer/account/login">
                                                                <a className={styles.loginBtnDesktop}>
                                                                    {t('product:unregisteredLogin')}
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    ) : (
                                                        <div className={styles.loginWrapper}>
                                                            <Typography letter="capitalize" className={styles.loginInfoText} variant="p" type="bold">
                                                                {companyStatus?.split('_').join(' ')}
                                                            </Typography>
                                                        </div>
                                                    )
                                                }
                                            </>
                                        )
                                    }
                                </>
                            ) : (
                                <>
                                    {
                                        isLogin !== 1 ? (
                                            <div className={styles.loginWrapper}>
                                                <Typography className={styles.loginInfoText} variant="p" type="regular">
                                                    {t('product:unregisteredMessage')}
                                                </Typography>
                                                <Link href="/customer/account/login">
                                                    <a className={styles.loginBtnDesktop}>
                                                        {t('product:unregisteredLogin')}
                                                    </a>
                                                </Link>
                                            </div>
                                        ) : (
                                            <div className={styles.loginWrapper}>
                                                <Typography letter="capitalize" className={styles.loginInfoText} variant="p" type="bold">
                                                    Anda Tidak Memiliki Customer Code
                                                </Typography>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        }
                    </div>
                </div>
                <div className={classNames(styles.tabs, 'col-xs-12 col-lg-12 hidden-mobile')}>
                    <TabsView
                        {...props}
                        dataInfo={expandData}
                        smartProductTabs={smartProductTabs || {
                            tab_2: {
                                label: null,
                                content: null,
                            },
                        }}
                    />
                </div>
                <RelatedProductCaraousel
                    t={t}
                    dataProduct={data}
                    isLogin={isLogin}
                    companyStatus={companyStatus}
                    customerCode={customerCode}
                    storeConfig={storeConfig}
                />
                <div className={classNames(styles.footer, 'hidden-desktop')}>
                    {
                        (isLogin === 1 && customerCode) ? (
                            <>
                                {
                                    (isLogin === 1 && companyStatus === 'approved') ? (
                                        <>
                                            {
                                                (verdorProductData.vendor_id && verdorProductData.final_price) ? (
                                                    <Button
                                                        className={styles.btnAddToCard}
                                                        color="primary"
                                                        onClick={handleOption}
                                                        // disabled={data && data.stock_status === 'OUT_STOCK'}
                                                    >
                                                        <img src="/assets/img/mobilecart.svg" alt="cart" />
                                                        <Typography variant="span" align="center" type="bold" letter="uppercase" color="white">
                                                            {t('product:addToCart')}
                                                        </Typography>
                                                    </Button>
                                                ) : (
                                                    <div className={styles.notifyWrapper}>
                                                        <Typography className={styles.stockInfo} variant="p" type="regular">
                                                            Beri tahu saya ketika ready stok
                                                        </Typography>
                                                        <Button
                                                            className={styles.btnNotify}
                                                            customRootStyle={{
                                                                width: 'auto',
                                                            }}
                                                            color="primary"
                                                            align="left"
                                                            onClick={handleSubscribeClick}
                                                        >
                                                            <NotificationsNoneOutlinedIcon />
                                                            <span className="notify-text">
                                                                {subscribeBtnText}
                                                            </span>
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {
                                                isLogin !== 1 ? (
                                                    <div className={styles.loginWrapper}>
                                                        <Typography className={styles.loginInfoText} variant="p" type="regular">
                                                            {t('product:unregisteredMessage')}
                                                        </Typography>
                                                        <Link href="/customer/account/login">
                                                            <a className={styles.loginBtnDesktop}>
                                                                {t('product:unregisteredLogin')}
                                                            </a>
                                                        </Link>
                                                    </div>
                                                ) : (
                                                    <div className={styles.loginWrapper}>
                                                        <Typography letter="capitalize" className={styles.loginInfoText} variant="p" type="bold">
                                                            {companyStatus?.split('_').join(' ')}
                                                        </Typography>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                {
                                    isLogin !== 1 ? (
                                        <div className={styles.loginWrapper}>
                                            <Typography className={styles.loginInfoText} variant="p" type="regular">
                                                {t('product:unregisteredMessage')}
                                            </Typography>
                                            <Link href="/customer/account/login">
                                                <a className={styles.loginBtnDesktop}>
                                                    {t('product:unregisteredLogin')}
                                                </a>
                                            </Link>
                                        </div>
                                    ) : (
                                        <div className={styles.loginWrapper}>
                                            <Typography letter="capitalize" className={styles.loginInfoText} variant="p" type="bold">
                                                Anda Tidak Memiliki Customer Code
                                            </Typography>
                                        </div>
                                    )
                                }
                            </>
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default ProductPage;
