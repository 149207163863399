import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, PRIMARY, GRAY_PRIMARY } from '@theme_color';
import {
    CreateMargin,
    CreatePadding,
    FlexRow,
    FlexColumn,
    showHide,
} from '@theme_mixins';
import { FONT_BIG } from '@theme_typography';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        marginTop: '34px',
    },
    heading: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: 0,
    },
    appBar: {
        position: 'relative',
        backgroundColor: WHITE,
        ...CreatePadding(10, 10, 10, 10),
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        height: '51px',
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnClose: {
        position: 'absolute',
        left: 10,
    },
    iconClose: {
        ...FONT_BIG,
        color: PRIMARY,
    },
    title: {
        justifySelf: 'center',
        ...CreateMargin(16, 0, 16, 0),
    },
    body: {
        ...FlexColumn,
        position: 'relative',
        height: '100%',
    },
    textSearch: {
        ...FlexRow,
        justifyContent: 'space-between',
        ...CreatePadding(0, '15%', 0, 0),
    },
    rmMargin: {
        ...CreateMargin(0, 0, 0, 0),
    },
    result: {
        ...FlexColumn,
        ...CreateMargin(16, 0, 30, 0),
    },
    textValue: {
        ...FlexColumn,
        ...CreateMargin(10, 0, 10, 0),
    },
    ...showHide,
    fieldContainer: {
        ...CreatePadding(10, 30, 10, 30),
        paddingBottom: 18,
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
    },
    fieldContainerLast: {
        ...CreatePadding(10, 30, 10, 30),
        paddingBottom: 18,
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        marginBottom: 80,
    },
    last: {
        marginBottom: 70,
    },
    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        backgroundColor: WHITE,
        borderTop: `1px solid ${GRAY_PRIMARY}`,
        ...CreatePadding(20, 20, 20, 20),
    },
    btnSave: {
        display: 'block',
        margin: 'auto',
        width: 'calc(100% - 12px)',
        height: 41,
    },
    btnSavePrice: {
        float: 'right',
    },
    listCategoryWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1fr)',
    },
    listCategory: {
        cursor: 'pointer',
        margin: '0 0 8px 0',
        // padding: '0 0 5px 5px',
        listStyleType: 'none',
        '& span': {
            fontSize: '14px',
            margin: 0,
        },
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
        '&:active': {
            backgroundColor: '#dedede',
        },
        '&:focus': {
            backgroundColor: '#dedede',
        },
    },
    listCategoryAmount: {
        color: '#989898',
        fontSize: '12px !important',
    },
    checkboxCustom: {
        display: 'block',
        paddingRight: 0,
    },
    accordionWrapper: {
        boxShadow: 'none',
        marginBottom: '38px',
        border: 'none',
        '&.MuiAccordion-root.Mui-expanded': {
            margin: '0',
            marginBottom: '38px',
        },
        '&.MuiAccordion-root:before': {
            backgroundColor: '#fff',
        },
    },
    filterTitle: {
        padding: '0 0 5px 0',
        minHeight: 'auto',
        borderBottom: '1px solid #989898',
        '& .MuiAccordionSummary-content': {
            margin: 0,
        },
        '&.MuiAccordionSummary-root.Mui-expanded': {
            minHeight: 'auto',
        },
        '& .MuiIconButton-root': {
            padding: '0',
        },
    },
    accordionDetail: {
        padding: '12px 0 0 16px',
    },
    customRadio: {
        '& .MuiRadio-root': {
            color: '#7B9AAF',
        },
        '& .MuiRadio-root.Mui-checked': {
            color: '#6BC498',
        },
    },
    customCheckbox: {
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#6BC498 !important',
        },
    },
}));

export default useStyles;
