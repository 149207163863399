import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SECONDARY } from '@theme_color';
import { FlexRow, CreatePadding } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    filterContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        // ...CreatePadding(15, 15, 15, 15),
    },
    countProductText: {
        // ...FONT_10,
        // marginLeft: 20,
        // paddingTop: 3,
        fontSize: '12px',
        textTransform: 'uppercase',
    },
    filterBtnContainer: {
        justifyContent: 'flex-end',
        ...FlexRow,
        alignItems: 'center',
        fontSize: 12,
    },
    btnFilter: {
        // marginRight: -20,
        border: '1px solid #6BC498',
        borderRadius: '15px',
        fontWeight: 'normal',
        padding: '3px 12px',
        fontSize: '10px',
        textTransform: 'capitalize',
    },
    iconFilter: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    iconGrid: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    iconList: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    leftWrapperFilter: {
        ...FlexRow,
        ...CreatePadding(2, 0, 2, 0),
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabs: {
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_SECONDARY}`,
    },
    productContainer: {
        overflow: 'hidden',
        ...CreatePadding(0, 0, 20, 0),
    },
    btnLoadmore: {
        cursor: 'pointer',
        width: '100%',
        padding: '20px',
        fontSize: '12px',
        background: '#fff',
        border: 'none',
        color: '#B4B4B4',
    },
    customProductWrapper: {
        paddingLeft: '104px',
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
    },
    countProductTextDesktop: {
        justifyContent: 'flex-start',
        fontSize: 12,
        marginTop: -25,
        textTransform: 'uppercase',
    },
    divLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    hideDivLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
        visibility: 'hidden',
    },
    loadMoreProduct: {
        fontSize: '16px',
        fontWeight: 'bold',
        display: 'inline-block',
        borderRadius: '5px',
        textTransform: 'capitalize',
        border: '1px solid #000000',
        padding: '12px 33px',
    },
}));

export default useStyles;
