import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    WHITE, GRAY_SECONDARY,
} from '@theme_color';
import {
    CenterAbsolute, CreateMargin, CreatePadding, FlexColumn, FlexRow,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '0 !important',
        width: '100%',
        height: '100%',
        padding: '22px 41px',
        [theme.breakpoints.down('sm')]: {
            ...FlexColumn,
            padding: '0px',
        },
        position: 'relative',
        '& .customizable-container': {
            marginTop: 20,
        },
        '& .product-video': {
            overflow: 'hidden',
            position: 'relative',
            width: '100%',
            '& iframe': {
                height: 'auto',
                padding: 'auto 0',
            },
            [theme.breakpoints.up('sm')]: {
                '& iframe': {
                    height: '416px',
                    // height: '572px',
                },
            },
        },
    },
    desktopBreadcrumb: {
        padding: 0,
        '& nav': {
            marginBottom: '20px',
        },
    },
    headContainer: {
        position: 'relative',
        backgroundColor: WHITE,
        width: '100%',
        padding: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(15, 35, 0, 35),
        ...FlexColumn,

        [theme.breakpoints.down('sm')]: {
            borderBottom: 'none',
            ...CreatePadding(0, 0, 0, 0),
        },
    },

    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        left: 0,
        ...CenterAbsolute,
        background: '#ffffff',
        ...CreatePadding(20, 16, 20, 16),
        zIndex: theme.zIndex.drawer + 1,
    },

    title: {
        [theme.breakpoints.up('sm')]: {
            fontSize: 30,
        },
    },

    btnAddToCard: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 316,
        },
        height: 44,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        background: '#185A58',
        borderRadius: '5px',
        '& img': {
            marginRight: '10px',
        },
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },

    titleContainer: {
        ...FlexRow,
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 16px',
        },
    },
    ratingWrapper: {
        ...FlexRow,
        alignItems: 'center',
        paddingBottom: '19px',
        borderBottom: '1px solid #F1F1F1',
        marginBottom: '19px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 16px 19px 16px',
        },
    },
    priceWrapper: {
        marginBottom: '16px',
        '& .price_text': {
            fontSize: 30,
        },
        '& .sub-strike strike': {
            fontSize: '16px',
        },
        '& .empty-price-div': {
            minHeight: '0 !important',
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
            '& .sub-strike strike': {
                fontSize: '14px',
            },
            padding: '0px 16px',
        },
    },
    titlePriceContainer: {
        ...FlexColumn,
        flex: 1,
        fontSize: '30px',
        marginBottom: '24px',
        '& .price_text': {
            fontSize: 30,
        },
        [theme.breakpoints.down('sm')]: {
            '& .price_text': {
                fontSize: 18,
            },
        },
    },

    shareContainer: {
        ...FlexRow,
        justifyContent: 'flex-end',
        flex: 1,
        '& > div': {
            textAlign: 'right !important',
        },
        [theme.breakpoints.down('sm')]: {
            '& button span': {
                fontSize: 9,
            },
        },
    },

    shareRootContainer: {
        ...CreatePadding(0, 0, 0, 0),
        ...FlexColumn,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 40,
        },
    },

    desktopShare: {
        ...FlexRow,
        alignItems: 'end ',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 16px',
        },
    },

    leftShare: {
        ...FlexColumn,
        paddingRight: '38px',
        borderRight: '1px solid #F1F1F1',
        [theme.breakpoints.down('sm')]: {
            paddingRight: '17px',
        },
    },
    rightShare: {
        paddingLeft: '30px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '17px',
        },
    },
    btnShare: {
        margin: '0px !important',
    },

    iconShare: {
        color: 'red',
        fontSize: 25,
        ...CreateMargin(0, 0, 0, 0),
    },

    carouselContainer: {
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 70,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    carouselTitle: {
        marginBottom: '20px',
    },
    desc: {
        ...CreateMargin(12, 0, 12, 0),
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 16px',
        },
    },
    ratingContainer: {
        ...FlexRow,
        marginLeft: '28px',
        '& .MuiSvgIcon-root': {
            fontSize: '16px !important',
        },
        '& p': {
            fontSize: '12px',
            color: '#272727',
        },
    },
    priceTiersContainer: {
        ...FlexColumn,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 16,
        },
        '& > *': {
            flex: '0 0 100%',
            margin: '5px 0',
            fontSize: 12,
        },
    },
    btnLoadReview: {
        // ...Centering,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        marginTop: '33px',
        marginBottom: '33px',
    },
    btnReviewContent: {
        border: '1px solid #000000',
        borderRadius: 0,
        padding: '8px 20px',
        '& .MuiTypography-root': {
            fontSize: '16px',
        },
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        marginLeft: '0 !important',
        '& p': {
            fontSize: '12px',
            color: '#272727',
        },
    },
    tabs: {
        paddingTop: '40px',

        [theme.breakpoints.down('sm')]: {
            paddingTop: '140px',
        },
    },
    shareTitle: {
        margin: 0,
        marginBottom: '7px',
        padding: 0,
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    bannerProduct: {
        width: '99%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            height: '416px',
            // height: '572px',
        },
    },
    bannerLiteTop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    bannerLiteTopMobile: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    bannerLiteLabel: {
        ...CreatePadding(10, 0, 10, 0),
    },
    bannerLiteAfter: {
        ...CreatePadding(10, 0, 10, 0),
    },
    shareIcon: {
        width: '100%',
    },
    rowItem: {
        ...FlexRow,
        justifyContent: 'space-around',
        width: '350px',
    },
    btnCompare: {
        padding: 0,
        marginTop: '6px',
        justifyContent: 'start',
        '& p': {
            padding: 0,
            margin: 0,
            marginLeft: '10px',
            fontSize: '14px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '12px',
            },
        },
    },
    loginWrapper: {
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '21px 23px',
        background: '#6BC4981A',
        borderRadius: '11px',
        [theme.breakpoints.down('sm')]: {
            padding: '0',
            background: '#FFF',
            borderRadius: '0',
            width: '100%',
        },
    },
    loginInfoText: {
        margin: 0,
        padding: 0,
        fontSize: '14px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '244px',
        },
    },
    loginBtnDesktop: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#FFF',
        padding: '12px 24px',
        borderRadius: '5px',
        backgroundColor: '#185A58',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    outStockText: {
        padding: 0,
        margin: 0,
        color: '#FA2E2C',
        fontSize: '16px',
        marginBottom: '31px',
    },
    notifyWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'space-between',
        },
    },
    stockInfo: {
        color: '#989898',
        fontSize: '12px',
        margin: '0 0 0 17px',
        [theme.breakpoints.down('sm')]: {
            margin: '0',
        },
    },
    btnNotify: {
        borderRadius: '5px',
        backgroundColor: '#185A58',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        width: 'auto',
        '& .notify-text': {
            fontSize: '16px',
            marginLeft: '16px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '0',
            },
        },
        '& div:not(.MuiCircularProgress-root)': {
            border: '3px solid blue',
            width: 'auto',
        },
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
        },
    },
}));

export default useStyles;
