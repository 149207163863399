/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@common_typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RangeSlider from '@common_rangeslider';
import CheckBox from '@common_checkbox';
import CheckBoxSize from '@common_forms/CheckBoxSize';
import CheckBoxColor from '@common_forms/CheckBoxColor';
import RadioGroup from '@common_radio';
import Skeleton from '@common_skeleton';
import Button from '@common_button';
import useStyles from '@plugin_productlist/components/FilterDesktop/style';
import { useState } from 'react';

let globalTimeout = null;

const ViewFilter = (props) => {
    const {
        itemProps = {},
        elastic = false,
        t,
        tabs,
        loading,
        priceRange,
        setPriceRange,
        selectedFilter,
        setCheckedFilter,
        setSelectedFilter,
        handleSave,
        handleClear,
        category,
        onChangeTabs,
        isSearch,
        filter,
        storeConfig,
        isLogin,
    } = props;
    const styles = useStyles();
    const checkedFilter = (field, value) => {
        if (globalTimeout) {
            clearTimeout(globalTimeout);
        }
        setCheckedFilter(field, value);
        globalTimeout = setTimeout(() => {
            handleSave();
        }, 1000);
    };

    const selectFilter = (field, value) => {
        if (globalTimeout) {
            clearTimeout(globalTimeout);
        }
        setSelectedFilter(field, value);
        globalTimeout = setTimeout(() => {
            handleSave();
        }, 1000);
    };

    const [first, setfirst] = useState(0);
    const setPrice = (value) => {
        setfirst(first + 1);
        setPriceRange(value);
    };

    React.useEffect(() => {
        if (first > 0) {
            handleSave();
        }
    }, [priceRange, first]);

    const generateFilter = (data, itemFilter, idx) => {
        const ItemValueByLabel = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < itemFilter.value.length; index++) {
            ItemValueByLabel.push({
                label: itemFilter.value[index].label,
                value: itemFilter.value[index].label,
            });
        }
        if (itemFilter.field !== 'attribute_set_id') {
            if (itemFilter.field === 'price') {
                const price = priceRange;
                price[1] = price[1] || parseInt(itemFilter.value[itemFilter.value.length - 1].value);
                return (
                    <div key={idx} style={{ width: '100%' }}>
                        <RangeSlider
                            noLabel
                            label={itemFilter.label}
                            maxValue={parseInt(itemFilter.value[itemFilter.value.length - 1].value)}
                            value={price}
                            onChange={itemProps.priceRangeChange || setPrice}
                            storeConfig={storeConfig}
                        />
                    </div>
                );
            }
            if (itemFilter.field === 'color') {
                return (
                    <div key={idx}>
                        <CheckBox
                            className={styles.checkboxCustom}
                            name={itemFilter.field}
                            noLabel
                            label={itemFilter.label || t('catalog:title:color')}
                            data={ItemValueByLabel}
                            value={selectedFilter[itemFilter.field] ? selectedFilter[itemFilter.field].split(',') : []}
                            flex={itemProps.selectSizeFlex || 'row'}
                            CustomItem={itemProps.selectColorItem || CheckBoxColor}
                            onChange={(val) => checkedFilter(itemFilter.field, val)}
                        />
                    </div>
                );
            }
            if (itemFilter.field === 'size') {
                return (
                    <div key={idx}>
                        <CheckBox
                            className={styles.checkboxCustom}
                            name={itemFilter.field}
                            noLabel
                            label={itemFilter.label || t('catalog:title:size')}
                            data={ItemValueByLabel}
                            value={selectedFilter[itemFilter.field] ? selectedFilter[itemFilter.field].split(',') : []}
                            flex={itemProps.selectSizeFlex || 'row'}
                            CustomItem={itemProps.selectSizeItem || CheckBoxSize}
                            onChange={(val) => checkedFilter(itemFilter.field, val)}
                        />
                    </div>
                );
            }
            if ((itemFilter.field === 'cat' || itemFilter.field === 'category_id')) {
                return (
                    <div className={styles.listCategoryWrapper}>
                        {itemFilter.value.map((val, ids) => {
                            if (val !== 'attribute_set_id') {
                                return (
                                    <span
                                        onClick={
                                            (e) => {
                                                if (onChangeTabs) {
                                                    onChangeTabs(e, ids + 1);
                                                } else {
                                                    selectFilter(itemFilter.field, val.value);
                                                }
                                            }
                                        }
                                        className={styles.listCategory}
                                        key={ids}
                                    >
                                        <Typography variant="span" letter="capitalize">
                                            {`${val.label.replace(/_/g, ' ')}`}
                                        </Typography>
                                        {' '}
                                        <Typography className={styles.listCategoryAmount} variant="span" letter="capitalize">
                                            {`(${val.count})`}
                                        </Typography>
                                    </span>
                                );
                            }

                            return null;
                        })}
                    </div>
                );
            }
            if ((itemFilter.field === 'cat' || itemFilter.field === 'category_id') && !isSearch) {
                return <span key={idx} />;
            }
            return (
                <div key={idx}>
                    {elastic ? (
                        <CheckBox
                            field={itemFilter.field}
                            className={styles.customCheckbox}
                            noLabel
                            label={itemFilter.label || ''}
                            data={ItemValueByLabel}
                            value={selectedFilter[itemFilter.field] ? selectedFilter[itemFilter.field].split(',') : []}
                            flex="column"
                            onChange={(val) => checkedFilter(itemFilter.field, val)}
                        />
                    ) : (
                        <RadioGroup
                            noLabel
                            className={styles.customRadio}
                            name={itemFilter.field}
                            label={itemFilter.label || ''}
                            valueData={itemFilter.value || []}
                            value={selectedFilter[itemFilter.field]}
                            onChange={(value) => selectFilter(itemFilter.field, value)}
                        />
                    )}
                </div>
            );
        }
        return null;
    };
    return (
        <div className={styles.root}>
            {loading ? <Skeleton variant="rect" width="100%" height={705} /> : null}
            {filter
                && filter.map((itemFilter, idx) => {
                    if ((itemFilter.field === 'cat' || itemFilter.field === 'attribute_set_id') && !isSearch) {
                        return <span key={idx} />;
                    }
                    if ((itemFilter.field === 'price' || itemFilter.field === 'attribute_set_id') && !isLogin) {
                        return <span key={idx} />;
                    }
                    return (
                        <Accordion className={styles.accordionWrapper} key={idx} defaultExpanded={typeof selectedFilter[itemFilter.field] !== 'undefined'}>
                            <AccordionSummary className={styles.filterTitle} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <Typography className={styles.heading} variant="span" letter="capitalize">
                                    {itemFilter.label.replace(/_/g, ' ')}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails className={styles.accordionDetail}>{generateFilter(filter, itemFilter, idx)}</AccordionDetails>
                        </Accordion>
                    );
                })}
        </div>
    );
};

export default ViewFilter;
